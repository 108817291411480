/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/*html, body {
  max-width: 800px;
  margin: 0 auto;
 }*/

ion-card {
  box-shadow: none;
  background: white
}

/*ion-grid {
    --ion-grid-padding: 0px;
}

ion-grid-content {
    --ion-grid-content-padding: 0px;
}

ion-button i {
    margin-right: 8px;
}

ion-button {
    --border-radius: 8px
}*/

ion-spinner {
  max-height: 15px;
}

input[type="text"], textarea{
  border: 1px solid var(--ion-background-color-step-50);
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  resize: none;
  background: var(--ion-background-color-step-100);
  color: var(--ion-color-dark);
}

input[type="file"]{
  display: none;
}

ion-button, label.file-upload{
  --padding-bottom: 8px;
  --padding-top: 8px;
  min-height: 0;
  --border-radius: 8px;
  --border-color: var(--ion-background-color-step-50);
  --border-style: solid;
  --border-width: 1px;
  --background: var(--ion-background-color-step-100);
  --background-hover: var(--ion-background-color-step-50);
  --background-activated: var(--ion-background-color-step-50);
  --background-focused: var(--ion-background-color-step-50);
  --color: var(--ion-color-dark);
}

label.file-upload>ion-icon{
  margin-top: 2px;
  margin-right: 8px;
}

ion-tab-button {
  border-top: 3px solid transparent;
}

ion-tab-button.tab-selected {
  border-top: 3px solid var(--ion-color-danger);
}

ion-tab-button.tab-selected ion-icon .cls-2 {
  fill: red;
}

button.alert-button.alert-button-danger {
  color: var(--ion-color-danger);
}

ion-app,
ion-content {
  --background: #f3f2f7;
  /* Grauton für den Light Mode */
}

ion-item {
  --background: white;
  /* Leicht dunklerer Grauton für Listenelemente im Light Mode */
}

ion-list {
  --ion-item-background: --ion-background;
}

ion-chip[size="small"] {
  height: 25px;
  min-height: 25px;
  font-size: 10px;
  padding: 0 8px 0 10px;
  border-radius: 6px;
}

ion-chip[size="small"] ion-icon {
  margin-inline-end: 3px;
}

@media (prefers-color-scheme: dark) {

  ion-app,
  ion-content {
    --background: black;
    /* Grauton für den Dark Mode */
  }

  ion-item {
    --background: #1c1c1e;
    /* Dunklerer Grauton für Listenelemente im Dark Mode */
    //--background: #121212;
  }

  ion-card {
    background: var(--ion-color-light);
    //background: #121212
  }
}

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
@import "@ionic/angular/css/palettes/dark.system.css";


:root {
  --ion-color-primary: var(--ion-color-dark);
  --ion-color-primary-rgb: var(--ion-color-dark-rgb);
  --ion-color-primary-contrast: var(--ion-color-dark-contrast);
  --ion-color-primary-contrast-rgb: var(--ion-color-dark-contrast-rgb);
  --ion-color-primary-shade: var(--ion-color-dark-shade);
  --ion-color-primary-tint: var(--ion-color-dark-tint);

  /*--ion-color-danger: #DF1717;
	--ion-color-danger-rgb: 223,23,23;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #c41414;
	--ion-color-danger-tint: #e22e2e;



    --fc-small-font-size: .85em;
    --fc-page-bg-color: var(--ion-card-background);
    --fc-neutral-bg-color: var(--ion-color-base)
    --fc-neutral-text-color: grey;
    --fc-border-color: var(--ion-color-light);
    --fc-button-text-color: var(--ion-color-base);
    --fc-button-bg-color: var(--ion-color-light);
    --fc-button-border-color: var(--ion-color-light);
    --fc-button-hover-bg-color: var(--ion-color-light);
    --fc-button-hover-border-color: var(--ion-color-light);
    --fc-button-active-bg-color: var(--ion-color-light);
    --fc-button-active-border-color: var(--ion-color-light);
    --fc-event-bg-color: #3788d8;
    --fc-event-border-color: #3788d8;
    --fc-event-text-color: #fff;
    --fc-event-selected-overlay-color: rgba(0,0,0,.25);
    --fc-more-link-bg-color: #d0d0d0;
    --fc-more-link-text-color: inherit;
    --fc-event-resizer-thickness: 8px;
    --fc-event-resizer-dot-total-width: 8px;
    --fc-event-resizer-dot-border-width: 1px;
    --fc-non-business-color: hsla(0,0%,84%,.3);
    --fc-bg-event-color: #8fdf82;
    --fc-bg-event-opacity: 0.3;
    --fc-highlight-color: rgba(188,232,241,.3);
    --fc-today-bg-color: var(--ion-color-light);
    --fc-now-indicator-color: red;*/
}

.fc .fc-daygrid-event {
  height: 4px;
  /* Ändern Sie die Höhe des Events */
  line-height: 4px;
  /* Stellen Sie sicher, dass der Text richtig zentriert ist */
  font-size: 0px;
  /* Ändern Sie die Schriftgröße des Textes */
  opacity: 0.5;
}

/* Abgerundete Ecken und Hintergrundfarbe für den FullCalendar */
.fc {
  border-radius: 10px;
  /* Anpassung der Abrundung */
  background-color: var(--ion-card-background);
  /* Anpassung der Hintergrundfarbe, sofern in den Variablen definiert */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  /* Optional: leichte Schatten für mehr Tiefe */
  padding: 16px;
}

/* Stellen Sie sicher, dass auch die inneren Elemente des Kalenders die abgerundeten Ecken berücksichtigen */
.fc .fc-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.fc .fc-daygrid {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.fc .fc-toolbar button {
  border-radius: 50px;
  /* Völlig abgerundete Buttons */
  border: none;
  /* Entfernen von Randlinien */
  margin: 0 5px;
  /* Abstand zwischen den Buttons */
}

.fc .fc-toolbar-title {
  font-size: 18px;
  font-weight: bold;
  /* Schriftgröße des Titels verringern */
}
